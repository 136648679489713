<template>
  <b-card>
    <!-- form-->
    <validation-observer ref="simpleRules">

      <b-form @submit.prevent>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Old Password"
              class="oldLabel"
              label-for="account-old-password"
            >
              <validation-provider
                id="Old Password"
                #default="{ errors }"
                name="Old Password"
                rules="required"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input
                    id="account-old-password"
                    v-model="form.old_password"
                    name="old-password"
                    :state="errors.length > 0 ? false:null"

                    :type="passwordFieldTypeOld"
                    placeholder="Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer togglePasswordOld"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
        <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="New Password"
              class="newLabel"
            >
              <validation-provider
                #default="{ errors }"
                name="New Password"
                vid="New Password"
                rules="required|min:8|password"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input
                    id="account-new-password"
                    v-model="form.password"
                    :state="errors.length > 0 ? false:null"

                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer togglePasswordNew"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger-2">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Retype New Password"
              class="confirmLabel"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:New Password"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="form.confirm_password"
                    :type="passwordFieldTypeRetype"
                    :state="errors.length > 0 ? false:null"

                    name="retype-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer togglePasswordRetype"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loading"
              variant="primary"
              class="mt-1 mr-1 changePassword"
              type="submit"
              @click="changePassword"
            >
              Save changes
              <div v-if="loading" class="spinner-border spinner-border-sm" />

            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1 resetForm"
              @click="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive/src/ripple'
import profileService from '@/services/profile/profile.service'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { password } from '@core/utils/validations/validations'
import errorResponseHandler from '@/services/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { resConditionCheck } from '@/helpers/constant'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        old_password: '',
        password: '',
        confirm_password: '',
      },

      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      password,
      loading: false
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    resetForm() {
      this.form.old_password = ""
      this.form.password = ""
      this.form.confirm_password = ""
    },

    changePassword() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          profileService.changePassword(this.form).then(res => {
            if (resConditionCheck(res.status) && res.data) {
              this.loading = false
              this.$router.replace({
                name: "dashboard",
              });
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          }).catch(error => {
            this.loading = false
            const errorData = errorResponseHandler(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          })
        }
      })
    }
  },
}
</script>

<style scoped>
.text-danger-2 {
    color: #ea5455 !important;
}
</style>
