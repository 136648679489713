import axios from 'axios'

function getProfileInfo() {
  return axios.get(`admin`)
}

function updateProfileInfo(data) {
  return axios.put('admin/edit-profile', data)
}

function changePassword(data) {
  return axios.post('admin/change-password', data)
}

export default {
  getProfileInfo,
  updateProfileInfo,
  changePassword
}
