<template :key="componentKey">
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside v-if="!imagePreview">
        <b-link>
          <b-avatar
            size="80"
            variant="light-primary"
            :src="staticLink"
            class="badge-minimal"
            badge-variant="success"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-aside v-if="imagePreview">
        <b-link>
          <!-- <b-img :src="imagePreview" rounded height="80" /> -->
		  <b-avatar
            size="80"
            variant="light-primary"
            :src="imagePreview"
            class="badge-minimal"
            badge-variant="success"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <div class="fileUpload btn btn-primary">
          <span>Upload</span>
          <input
            id="uploadBtn"
            type="file"
            accept="image/*"
            class="upload"
            @change="fileSelected"
          >
        </div>

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class=" mr-75 resetImage"
          @click="resetImage"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 1MB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group label="Firstname" label-for="account-firstname">
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required|alpha|max:60"
              >
                <b-form-input
                  v-model="profileInfo.firstName"
                  class="first_name"
                  placeholder="First Name"
                  :state="errors.length > 0 ? false : null"
                  name="firstname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Lastname" label-for="account-lastname">
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required|alpha|max:60"
              >
                <b-form-input
                  v-model="profileInfo.lastName"
                  class="last_name"
                  name="lastName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Lastname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <b-form-input
                v-model="profileInfo.email"
                class="email"
                readonly
                name="email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              name="Gender"
              rules="required"
            >
              <b-form-group
                label="Select Gender"
                label-for="mc-select-role"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  v-model="profileInfo.gender"
                  class="gender"
                  placeholder="Select Gender"
                  :state="errors.length > 0 ? false : null"
                  :options="genderOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group></validation-provider></b-col>

          <b-col sm="6">
            <b-form-group label="Dob" label-for="account-dob">
              <date-picker
                v-model="profileInfo.dob"
                class="dob"
                format="YYYY-MM-DD"
                type="date"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group label="Joining Date" label-for="account-joinDate">
              <date-picker
                v-model="profileInfo.joinDate"
                class="joinDate"
                disabled
                format="YYYY-MM-DD"
                type="date"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>

          <!-- alert -->
          <b-col cols="12" class="mt-75" />
          <!--/ alert -->

          <b-col cols="12" class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1 editProfileBtn"
              :disabled="loading"
              @click="editProfileInfo"
            >
              Save changes
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button variant="secondary" :to="{ name: 'dashboard' }">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<style scoped>
.fileUpload {
	position: relative;
	overflow: hidden;
	margin: 10px;
}
.fileUpload input.upload {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	font-size: 20px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
}
</style>

<script>
import {
	BButton,
	BForm,
	BFormGroup,
	BFormInput,
	BRow,
	BCol,
	BCard,
	BCardText,
	BMedia,
	BMediaAside,
	BMediaBody,
	BLink,
	BImg,
	BAvatar
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { genderOptions, resConditionCheck } from "@/helpers/constant";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
	max,
	required,
	email,
	confirmed,
	password,
	alpha
} from "@core/utils/validations/validations";
import moment from "moment";
import { mapState } from "vuex";
import errorResponseHandler from "@/services/errorHandler";
import profileService from "@/services/profile/profile.service";

export default {
	components: {
		BButton,
		BForm,
		BImg,
		BFormGroup,
		BFormInput,
		BRow,
		BCol,
		BCard,
		BCardText,
		BMedia,
		BMediaAside,
		BMediaBody,
		BLink,
		vSelect,
		DatePicker,
		ValidationProvider,
		ValidationObserver,
		BAvatar
	},
	directives: {
		Ripple
	},
	props: {
		generalData: {
			type: Object,
			default: () => {}
		}
	},

	data() {
		return {
			profileInfo: {
				firstName: "",
				lastName: "",
				email: "",
				gender: "",
				dob: "",
				joinDate: ""
			},
			required,
			componentkey: 0,
			password,
			email,
			max,
			confirmed,
			alpha,
			genderOptions,
			profileFile: null,
			selectedFile: null,
			staticLink: "",
			imagePreview: "",
			loading: false
		};
	},

	provide: {
		username: "Test"
	},

	computed: {
		...mapState(["auth"])
	},

	mounted() {
		this.getProfileInfo();
	},
	methods: {
		resetImage() {
			this.imagePreview = this.staticLink;
			this.selectedFile = null;
		},

		fileSelected(event) {
			// eslint-disable-next-line prefer-destructuring
			this.selectedFile = event.target.files[0];
			if (
				(this.selectedFile.type === "image/jpeg"
					|| this.selectedFile.type === "image/jpg"
					|| this.selectedFile.type === "image/png"
					|| this.selectedFile.type === "image/gif")
				&& this.selectedFile.size <= 1048576
			) {
				this.imagePreview = URL.createObjectURL(this.selectedFile);
			} else {
				this.selectedFile = null;
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Only JPG, JPEG, GIF or PNG Allowed with Max Size Of 1 MB",
						icon: "X-CircleIcon",
						variant: "danger"
					}
				});
			}
		},
		editProfileInfo() {
			this.$refs.simpleRules.validate().then(success => {
				if (success) {
					this.loading = true;
					const formdata = new FormData();
					this.profileInfo.dob = moment(this.profileInfo.dob).format(
						"YYYY-MM-DD"
					);
					formdata.append("first_name", this.profileInfo.firstName);
					formdata.append("last_name", this.profileInfo.lastName);
					formdata.append("email", this.profileInfo.email);
					formdata.append("gender", this.profileInfo.gender);
					formdata.append("dob", this.profileInfo.dob);
					if (this.selectedFile) {
						formdata.append(
							"profile_pic",
							this.selectedFile,
							this.selectedFile.name
						);
					}
					profileService
						.updateProfileInfo(formdata)
						.then(res => {
							this.loading = false;
							if (resConditionCheck(res.status) && res.data.data.user) {
								this.$refs.simpleRules.loading = false;
								this.$store.commit("auth/SET_USER", res.data.data.user);
								this.$store.commit("auth/SET_TOKEN", res.data.data.accessToken);
								this.$router.push("/dashboard");
								this.$toast({
									component: ToastificationContent,
									props: {
										title: res.data.message,
										icon: "CheckIcon",
										variant: "success"
									}
								});
							}
						})
						.catch(error => {
							this.loading = false;
							const errorData = errorResponseHandler(error);
							this.$toast({
								component: ToastificationContent,
								props: {
									title: errorData,
									icon: "X-CircleIcon",
									variant: "danger"
								}
							});
						});
				}
			});
		},

		getProfileInfo() {
			profileService
				.getProfileInfo()
				.then(res => {
					if (res.data.user_details && resConditionCheck(res.status)) {
						const dob = moment(
							res.data.user_details.dob,
							moment.defaultFormat
						).toDate();
						const joinDate = moment(
							res.data.user_details.createdDate,
							moment.defaultFormat
						).toDate();
						this.profileInfo.firstName = res.data.user_details.firstName;
						this.profileInfo.lastName = res.data.user_details.lastName;
						this.profileInfo.email = res.data.user_details.email;
						this.profileInfo.gender = res.data.user_details.gender;
						this.profileInfo.dob = dob;
						this.staticLink = res.data.user_details.profilePic;
						this.profileInfo.joinDate = joinDate;
					}
				})
				.catch(error => {
					const errorData = errorResponseHandler(error);
					this.$toast({
						component: ToastificationContent,
						props: {
							title: errorData,
							icon: "X-CircleIcon",
							variant: "danger"
						}
					});
				});
		}
	}
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
